import * as params from '@params';
const currentLang = params.currentLang;
const pageIsMultiLang = params.pageIsMultiLang;
const langKey = "lang";

function redirectToLang(lang) {
  // set lang in local storage
  localStorage.setItem(langKey, lang);
  // redirect to lang
  if (window.location.pathname.startsWith("/en/")) {
    window.location.pathname = window.location.pathname.replace("/en/", "/");
  } else {
    window.location.pathname = window.location.pathname.replace("/", "/" + lang + "/");
  }
}

document.addEventListener("DOMContentLoaded", (event) => {
  var lang = localStorage.getItem(langKey);
  if (lang === null) {
    lang = navigator.language || navigator.userLanguage;
    if (lang === "" || lang === "/" || lang.includes("de")) {
      lang = "de";
    } else {
      lang = "en";
    }
  }
  const query = new URLSearchParams(window.location.search);
  if (pageIsMultiLang && !(lang === currentLang) && !query.has("noredirect")) {
    redirectToLang(lang);
  }
});

document.addEventListener("languagechange", (event) => {
  const lang = event.detail.lang;
  if (pageIsMultiLang) redirectToLang(lang);
});

let ll = document.querySelector(".language-link");

if (ll) {
  document.querySelector(".language-link").addEventListener("click", (event) => {
    const url = event.target.dataset.lang;
    let lang;
    if (url.includes("/en/")) {
      lang = "en";
    } else {
      lang = "de";
    }
    redirectToLang(lang);
  });
}


